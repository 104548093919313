.k-grid-header {
	background-color: #fff;
}

.k-header.k-grid-toolbar {
	text-align: right;
}

tr.k-master-row.k-alt {
	background-color: #fff;
}

.k-textbox::selection, .k-input::selection, .k-textarea::selection {
	background-color: #007899;
}

.k-grid-header .k-i-sort-asc-sm {
	color: #007899;
}

.k-grid-header .k-i-sort-desc-sm {
	color: #007899;
}