/* AG Grid css overrides for Pacing */
.ag-row {
    background-color: #fff !important;
}

.ag-theme-balham .ag-row-selected {
    background-color: #CCF0E7 !important;
}

.ag-theme-balham .ag-row-selected .ag-cell {
    background-color: #CCF0E7 !important;
}

.ag-theme-balham .ag-row-selected.ag-row-hover .ag-cell {
    background-color: #CCF0E7 !important;
}

.ag-theme-balham {
    --ag-header-foreground-color: #FFF;
    --ag-header-background-color: #445460;
    --ag-header-column-separator-color: #FFF;
}

.ag-header-cell {
    font-weight: 400;
}

.ag-theme-balham .ag-header-icon {
    color: #FFF;
}

.ag-header-cell:last-child::after {
    display: none;
}

.ag-row-hover {
    background-color: #ebebeb !important;
}

.name-column {
    color: rgba(0, 120, 153, 1);
}

.name-column:hover {
    color: #f29121;
    cursor: pointer;
}

.ag-row-group {
    color: black;
}

.ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.ag-cell-value.ag-cell-focus {
    /* border: none;  */
}

.ag-cell-range-single-cell {
    border: none !important;
}

.ag-theme-balham .ag-cell-range-selected:not(.ag-cell-focus) {
    background-color: initial;
}

.ag-tooltip{
    background-color: #fff;
    padding: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: #545454;
    width: 500px;
 }

 .numeric-input {
    font-size: calc(var(--ag-font-size) + 1px);
    padding-left: calc(var(--ag-cell-horizontal-padding) - 1px);
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 1px solid #007899;
  }

  /* .ag-theme-balham .ag-cell.ag-cell-inline-editing {
    width: 250px !important;
  } */