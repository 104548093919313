$stepstoneGreen:    #DAFF96
$stepstoneBlue:		#2F3A42
$stepstoneGrey:     #C9C6CE
$stepstoneBG:       #445460
$white:				#fff

$off-state: 	$stepstoneGrey
$on-state: 		$stepstoneBlue

.switchButton
    height: 40px
    width: 80px
    border: 1px solid $stepstoneGrey
    background: $stepstoneBG
    color: $white
    font-family: 'Octave','Open Sans','Roboto',sans-serif
    font-weight: 500
    font-size: 12px

.switch-left
    border-radius: 5px 0 0 5px

.switch-right
    border-radius: 0 5px 5px 0

.switch-on
    background: $stepstoneGreen
    color: $stepstoneBlue