$primary-color: #007899;
$secondary-color: #fff;

button.swal2-cancel.cancel-button-class.swal2-styled {
  background-color: $secondary-color;
  border: 1px solid $primary-color;
  color: $primary-color;
  min-width: 120px;
}

button.swal2-confirm.confirm-button-class.swal2-styled {
  min-width: 120px;
  background-color: $primary-color;
  color: $secondary-color;
  border: 1px solid $primary-color;
}

.swal2-loading {
  margin-top: auto !important;

  .swal2-confirm {
    border: none !important;
    background-image: url(images/favicon.ico) !important;
    background-size: contain !important;
  }
}

.swal2-actions:not(.swal2-loading) {
  button.swal2-confirm.swal2-styled {
    min-width: 120px;
    background-color: $primary-color;
    color: $secondary-color;
    border: 1px solid $primary-color;
  }
}
